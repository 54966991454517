import React from 'react'

const CheckBoxCheckedIcon = () => {
    return (
        <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.500004 17.9365L0.500005 0.500001L26.169 0.500003C27.036 0.500003 27.8576 0.963382 28.4791 1.78484C29.1009 2.60659 29.5 3.76416 29.5 5.06353L29.5 17.9365C29.5 19.2358 29.1009 20.3934 28.4791 21.2152C27.8576 22.0366 27.036 22.5 26.169 22.5L3.83101 22.5C2.964 22.5 2.14239 22.0366 1.52088 21.2152C0.899153 20.3934 0.500004 19.2358 0.500004 17.9365Z" fill="#00FF82" stroke="#00FF82" />
        </svg>

    )
}

export default CheckBoxCheckedIcon